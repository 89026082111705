html {
  font-size: $font-size-base;
}

body {
  font-size: 0.875rem;
}

img {
  @include img-responsive();
}

.stars {
  img {
    display: inline;
  }
}

.btn,
button,
a {
  transition: color, background-color, opacity, border-color;
  transition-duration: $base-duration;
}

p {
  margin: 0 0 $base-spacing;
}

.btn-link {
  font-size: 1.125rem;
  text-transform: uppercase;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.configure {
    text-transform: none;
    font-size: inherit;
    font-family: inherit;
  }
}

.btn-creator,
.add-button {
  @extend .btn-sm;
}

.btn-icon {
  padding: 0;
  width: 50px;
  height: 50px;
}

.container {
  @media screen and (max-width: $screen-xs-max) {
    width: 100%; // Make sure container takes the whole space (e.g. in case it's flex item).
  }

  &--xlg {
    @media screen and (min-width: $screen-xlg-min) {
      width: $container-xlg;
    }
  }

  &--lg-tight {
    @media screen and (min-width: $screen-lg-min) {
      width: $container-desktop;
    }
  }
}

.btn-lg-xlg {
  @media screen and (min-width: $screen-lg-min) {
    @include button-size($btn-padding-large-vertical, 80px, $btn-font-size-large, $btn-line-height-large, $btn-border-radius-large);
  }
}

.dropdown-menu {
  animation: fadeIn $base-duration;
}

.text-success,
.icon-ok {
  color: $brand-success;
}

.content-content {
  > .form-inline {
    margin-bottom: $base-spacing;
  }
}

.m-heading {
  margin: 0;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.m-content-box {
  margin-bottom: $base-spacing;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-bottom: 47px;
  }

  &--mb-gutter {
    margin-bottom: ceil($grid-gutter-height / 2);
  }
}

.item-counter,
.m-item__badge {
  @extend .badge;
}

.promotion-availability {
  &__label {
    margin-right: 0.625em;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

// Group nav module
.m-group-nav {
  &__title {
    margin: 0;
  }

  &--horizontal {
    text-align: center;
    border-bottom: 1px solid $separator-color;

    .m-group-nav__link {
      &:after {
        @include line-ornament(100%, 3px, 0);
        position: relative;
        bottom: -12px;
        visibility: hidden;
      }

      &.is-active {
        &:after {
          visibility: visible;
        }
      }
    }

    .m-group-nav__title {
      @at-root .restaurant-menu & {
        display: none;
      }
    }
  }
}

// List module
.m-list {
  &__description {
    text-align: center;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      border-top: 1px solid $separator-color;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @at-root .m-group--full-width & {
      @extend .container--lg-tight;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

// Item module
.m-item {
  &__title {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
  }
}

// Consider theme.scss.liquid - START

// Consider theme.scss.liquid - END
