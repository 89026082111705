// Typography
$font-size-base: 16px;
$line-height-base: 1.7;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;

// Colors
$m-form-required-sign-color: #e91717;
$brand-success: #80bc68;
$separator-color: rgba(#000, 0.2); // ~#d3d3d3 on white background
$separator-color-light: rgba(#fff, 0.2);

// Layout
$grid-gutter-width: 30px;
$grid-gutter-height: $grid-gutter-width;
$container-large-desktop: (1170px + $grid-gutter-width);
$container-xlg: (1720px + $grid-gutter-width);
$screen-sm-min: 640px;
$container-sm: 100%;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xlg-min: 1750px;
$grid-float-breakpoint: $screen-md-min;
$border-radius-base: 0;
$border-radius-large: $border-radius-base;
$border-radius-small: $border-radius-base;
$minimax-m-content-padding: 55px;
$close-text-shadow: none;

// Modals
$modal-title-text-transform: uppercase;

// Tables
$table-cell-padding: 15px 8px;

// Labels
$labels-padding: .2em 1em .3em;
$labels-text-transform: uppercase;
$labels-font-weight: normal;
$labels-border-radius: $border-radius-base;

// Mobile navigation
$m-nav-toggle-font-size: 30px;
$mobile-navigation-breakpoint-max: ($screen-md-min - 1);
$mobile-navigation-header-margin: 0 0 30px;
$mobile-navigation-dropdown-style-disc: false;
$mobile-navigation-list-text-transform: uppercase;
$mobile-navigation-font-size: 1.25rem;
$mobile-navigation-dropdown-menu-font-size: inherit;

// Mobile app promo section
$m-promo-section-gutter-v: 0;
$m-promo-section-font-size: 1rem;

// Flex layout module
$m-flex-layout-content-width: 80%;
$m-flex-layout-aside-width: 20%;

// Select
$m-select-padding: 6px 30px 6px 12px;

// Close
$close-font-size: 1.8rem;

// Floating Action Button
$m-floating-action-btn-font-size: 34px;

// Group nav module
$m-group-nav-font-size: 1.25rem;
$m-group-nav-font-weight: $font-weight-bold;
$m-group-nav-title-padding: 30px;
$m-group-nav-title-font-size: 3.125rem;
$m-group-nav-text-transform: uppercase;
$m-group-nav-link-padding: 11px;
$m-group-nav-aside-link-border: 1px solid $separator-color;

// Group module
$m-group-header-text-transform: uppercase;

// Item module
$m-item-gutter-base: 15px;
$m-item-title-font-size: 1rem;
$m-item-description-font-size: 1rem;
$m-list-item-inner-border: true;

// List module
$m-list-title-font-size: 2.5rem;
$m-list-title-text-transform: uppercase;
$m-list-item-border-top: 1px solid $separator-color;

// Flex layout
$m-flex-layout-content-width: 70%;
$m-flex-layout-aside-width: 30%;

// Menu description
$m-menu-description-margin: 0 0 70px;
$m-menu-description-font-size: 1rem;

// Buttons
$btn-default-bg: transparent;
$btn-text-transform: uppercase;
$btn-border-radius-base: $border-radius-base;
$btn-padding-base-vertical: 11px;
$btn-padding-base-horizontal: 30px;

$btn-padding-small-vertical: 13px;
$btn-padding-small-horizontal: 30px;
$btn-font-size-small: 0.875rem;
$btn-line-height-small: 1.45;

$btn-font-size-large: 1.125rem;
$btn-padding-large-vertical: 12px;
$btn-padding-large-horizontal: 50px;
$btn-line-height-large: 1.25;

// Forms
$form-group-margin-bottom: 25px;
$input-border: rgba(#000, 0.2);
$input-box-shadow: none;
$input-height-base: 50px;

// Card
$card-background: transparent;
$card-margin-bottom: 0;
$card-padding: 0;
$card-box-shadow: none;

// Shared dependencies
@import 'base/shared-themes-dependencies';

// Custom styles
@import 'base/shared-essentials';
@import 'base/shared-themes';
@import 'modules/m-backdrop';
@import 'modules/m-articles';
@import 'modules/m-bubbles';
@import 'modules/m-fancy-boxes';
@import 'modules/m-special-box';
@import 'modules/m-media-cover';
@import 'modules/m-buttons';
@import 'modules/m-floating-action';

// New Vision
@import 'base/new_vision/common';
@import 'modules/new_vision/m-top';
@import 'modules/new_vision/m-header';
@import 'modules/new_vision/m-flex-group';
@import 'modules/new_vision/m-hero';
@import 'modules/new_vision/m-indicators';
@import 'modules/new_vision/m-top-bar';
@import 'modules/new_vision/m-nav';
@import 'modules/new_vision/m-section';
@import 'modules/new_vision/m-list-separated';
@import 'modules/new_vision/m-list-vertical';
@import 'modules/new_vision/m-info-boxes';
@import 'modules/new_vision/m-info-box';
@import 'modules/new_vision/m-boxes';
@import 'modules/new_vision/m-page-subheader';
@import 'modules/new_vision/m-featured';
@import 'modules/new_vision/m-menu-teaser';
@import 'modules/new_vision/m-testimonials';
@import 'modules/new_vision/m-teasers';
@import 'modules/new_vision/m-overlay';
@import 'modules/new_vision/m-steps';
@import 'modules/new_vision/m-breadcrumb';
@import 'modules/new_vision/m-checkout';
@import 'modules/new_vision/m-footer';
